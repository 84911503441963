<template>
  <div class="w-full mb-5">
    <h1 class="text-2xl font-bold">
      {{ $t("business.payment_provider.title") }}
    </h1>
  </div>
  <div class="w-full">
    <div v-if="businessPaymentGatewayMethods?.length > 0" class="mb-10 space-y-3">
      <div>
        <p class="font-bold">
          {{ $t('payment.settings.payment_provider.title') }}
        </p>
        <p class="text-gray-400">
          {{ $t('payment.settings.payment_provider.description') }}
        </p>
      </div>
      <app-alert-error v-if="errors.enabled" class="p-4 bg-error-badge" :message="errors.enabled[0]" />
      <div v-for="([paymentMethodName, paymentMethodId], paymentMethod) in Object.entries(PAYMENT_METHODS)" :key="paymentMethod">
        <div v-if="checkBusinessHasPaymentMethods(businessPaymentGatewayMethods, paymentMethodId)">
          <p class="text-xs text-gray-500 py-2">{{ paymentMethodName?.replaceAll('_', ' ') }}</p>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-5">
            <card-payment-provider
              v-for="businessPaymentGatewayMethod in businessPaymentGatewayMethods"
              :key="businessPaymentGatewayMethod.id"
              :data="businessPaymentGatewayMethod"
              v-show="checkBusinessHasPaymentMethod(businessPaymentGatewayMethod, paymentMethodId)"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="loading" class="flex justify-center mt-10"><app-icon-loading iconColor="primary" /></div>
    <div v-else class="flex flex-col items-center mt-20 space-y-3">
      <img class="w-32" src="@/assets/image/default-payment-provider.svg" />
      <span class="text-center font-bold">
        {{ $t('payment.settings.payment_provider.list_payment_provider') }}
      </span>
      <span class="text-center text-gray-400 w-1/3 text-sm">
        {{ $t('payment.settings.payment_provider.contact_support_desc') }}
      </span>
      <app-button width="w-1/3" :showf70Icon="false">
        {{ $t('general.contact_support') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import PAYMENT_METHODS from '@/utils/const/payment_methods'
import CardPaymentProvider from '../../payment/items/card-payment-provider.vue'
export default {
  data() {
    return {
      PAYMENT_METHODS: PAYMENT_METHODS
    }
  },
  components: {
    CardPaymentProvider
  },
  mounted() {
    this.$store.dispatch('paymentStore/getBusinessPaymentGatewayMethod', this.$route.query.business_id)
  },
  computed: {
    businessPaymentGatewayMethods() {
      return this.$store.getters['paymentStore/business_payment_gateway_method']
    },

    loading() {
      return this.$store.getters['paymentStore/loading']
    },

    errors() {
      return this.$store.getters['paymentStore/errors']
    },

    business() {
      return this.$store.getters["masterBusinessStore/business"];
    },
  },
  methods: {
    checkBusinessHasPaymentMethod(businessPaymentGatewayMethod, paymentMethodId) {
      return businessPaymentGatewayMethod?.payment_gateway_method_application?.payment_gateway_method?.payment_method_id == paymentMethodId
    },

    checkBusinessHasPaymentMethods(businessPaymentGatewayMethods, paymentMethodId) {
      return businessPaymentGatewayMethods.find(
        (businessPaymentGatewayMethod) =>
          businessPaymentGatewayMethod?.payment_gateway_method_application?.payment_gateway_method?.payment_method_id == paymentMethodId
      )
    },
  }
}
</script>
